import { Image } from "@storybook";
import "./mpc-setup.scss";
import { Button } from "@components/button";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/constants";
import { LoginUserState } from "@states/user";
import { useRecoilValue } from "recoil";
import { useCookie } from "@hooks/cookie";
import { loginToasterMessages } from "constant";
import { useNotification } from "@hooks/notification";
import { useWebSocket } from "@hooks/web-socket";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const MpcQr = ({ showButton = true, showLogo = true }) => {
    const navigate = useNavigate();
    const { MPC_SETUP_STATUS, LOGIN,NO_ROUTE } = ROUTES;
    const { set: setCookieToken } = useCookie();
    const tempLoginUser = useRecoilValue(LoginUserState);
    const { LOGIN_SUCCESS } = loginToasterMessages;
    const { successNotification } = useNotification();
    const { config } = usePermissionContext()

    const { socket } = useWebSocket();
    useEffect(() => {
        socket?.on(`mpc`, (data) => {
            if (data.type === "MESSAGE") {
                navigate(MPC_SETUP_STATUS, {state: {userId: data?.userId, isSuccess: data?.isSuccess} })
            }
        });
        return () => {
          socket?.off("mpc");
        };
    }, [socket]);

    useEffect(() => {
        if (!tempLoginUser?.token) {
            navigate(LOGIN)
        }
    }, [])

    const {logo: {Liquid}, svg: {app_qr_stage, app_qr_pp, app_qr_beta, app_qr_prod}} = imageConfig;

      
    const  API = typeof API_HOST !== "undefined" && API_HOST 

    const isPP = /pp/gi.test(API || "");
    const isBeta = /beta/gi.test(API || "");
    const isProd = /api.satschel.com/gi.test(API || "");
    
    const hostQR = () => {
        if (isPP) {
            return app_qr_pp;
        } else if (isBeta) {
            return app_qr_beta;
        } else if (isProd) {
            return app_qr_prod;
        } else {
            return app_qr_stage;
        }
    };

    const handleLogin = useCallback(() => {
        setCookieToken("userDetails", tempLoginUser);
        navigate(NO_ROUTE);
        successNotification(LOGIN_SUCCESS);
    }, [tempLoginUser]);

    return (
        <div className="mpc_setup_container">
            {showLogo && <div className="header">
                <Image
                    className="brand_logo"
                    fileName={Liquid}
                />
            </div>
            }
            <div className="body">
                <div>
                    <h2 className="heading">Initiate the wallet setup</h2>
                    <p className="para">Scan the QR code to install the {config?.name} app and secure your assets on the go</p>
                </div>
                <div className="qr-wrapper">
                    <Image className="qr_code"
                        fileName={hostQR()} />
                </div>
                {showButton && <div>
                    <Button type="btn-outline-blue" label={`Explore ${config?.name}`} handleClick={handleLogin} />
                </div>}
            </div>
        </div>
    );
};
export default MpcQr;