import { useCallback, useState } from "react";
import { useCookie, useNetwork, useNotification } from "hooks";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LoginUserState, darkThemeState, invitedUsersState } from "states";
import { useLocation, useNavigate } from "react-router-dom";
import { removeLocalStorage } from "@utils/common";
import { ROUTES } from "@routes/constants";
import { APIS } from "constant";
import {
  RemovalFileState,
  RemovalRequestFilesState,
  authorisedDeleteFileStatus,
  authorisedUploadedFileStatus,
} from "./state";

interface IUserDetails {
  _id: string;
  isTradeEnabled: boolean;
  status: string;
  type: string;
  firstName: string;
  lastName: string;
  phone: string;
  image: string;
  countryCode: string;
  email: string;
  name?: string;
}

export const UseAuthorisedUser = () => {
  const { COOWNERSREMOVALREQUESTCANCEL } = ROUTES;

  const [coownerId, setCoownerID] = useState("");

  const [invitedUser, setInvitedUser] =
    useRecoilState<IUserDetails[]>(invitedUsersState);
  const setOnRemovalFiles = useSetRecoilState<any>(RemovalFileState);
  const [fileStatus, setFileStatus] = useRecoilState(
    authorisedUploadedFileStatus
  );
  const deleteFileStatus = useRecoilValue(authorisedDeleteFileStatus);
  const setUserInfo = useSetRecoilState(LoginUserState);
  const setRemovalFiles = useSetRecoilState(RemovalRequestFilesState);
  const [coownerData, setCoownerData] = useRecoilState(invitedUsersState);
  const [removalDecision, setRemovalDecision] = useState(false);

  const { patch: denyRemoval, loading: denyLoading } = useNetwork();
  const { patch: approveRemoval, loading: acceptLoading } = useNetwork();
  const { get: getCoownerDocument, loading: removalFilesLoading } =
    useNetwork();
  const { put: updateDataRoomFileStatus, loading: updateDataroomFileLoading } =
    useNetwork();
  const { get, loading: removalFileLoading } = useNetwork();
  const { remove: deleteFile } = useNetwork();
  const { patch: cancelRomoval } = useNetwork();
  const { get: getusers } = useNetwork();
  const { post } = useNetwork();
  const { remove } = useNetwork();

  const location = useLocation();
  const navigate = useNavigate();
  const { set: setCookieToken, deleteCookie } = useCookie();
  const { successNotification, errorNotification } = useNotification();
  const setThemeDark = useSetRecoilState(darkThemeState);

  const removeAuthoisedUser = useCallback(
    async (id: string) => {
      await remove(`${APIS.COOWNER}/${id}`).then((res) => {
        if (res?.success === true) {
          getusers(APIS.COOWNER).then((res) => {
            setInvitedUser(res?.data);
          });
          successNotification("User deleted successfully");
        } else if (res?.success === false) {
          errorNotification(res?.message);
          const updatedArray = coownerData?.map((user: any) =>
            user?._id === res?.data?._id ? res?.data : user
          );
          setCoownerData(updatedArray);
        } else {
          errorNotification("something went wrong");
        }
      });
    },
    [
      coownerData,
      errorNotification,
      getusers,
      remove,
      setCoownerData,
      setInvitedUser,
      successNotification,
    ]
  );

  const uploadDocument = useCallback(
    async (payload: JSON) => {
      post(APIS.UPLOADDOCUMENT, payload);
    },
    [post]
  );

  const uploadedFileStatus = useCallback(
    async (obj: any) => {
      const {
        setVisibleCancelDataroom,
        deleteID,
        manageCoownerId,
        setEditingRemovalRequest,
        setVisibleMangeDelete,
        setDeleteId,
        setManageCoownerId,
        fileLength,
      } = obj;
      if (manageCoownerId) {
        if (manageCoownerId && fileLength === 0) {
          errorNotification("Please upload a minimum of one document");
        } else {
          await updateDataRoomFileStatus(
            `${APIS.UPLOADDOCUMENT}/${manageCoownerId}`,
            {
              files: fileStatus,
            }
          )
            .then((res) => {
              if (res?.success) {
                successNotification("Request saved successfully");
                deleteUplaodedFile();
                setVisibleCancelDataroom(false);
                setFileStatus([]);
                setEditingRemovalRequest(false);
                setVisibleMangeDelete(false);
                setManageCoownerId("");
              }
            })
            .catch((err) => console.log(err?.message));
        }
      } else if (deleteID && fileStatus.length > 0)
        await updateDataRoomFileStatus(`${APIS.UPLOADDOCUMENT}/${deleteID}`, {
          files: fileStatus,
        })
          .then((res) => {
            if (res?.success) {
              successNotification(res?.message ?? "Email sent successfully");
              deleteUplaodedFile();
              setVisibleCancelDataroom(false);
              setFileStatus([]);
              setDeleteId("");
              setInvitedUser((prev) => {
                const updateduser = prev.map((user) => {
                  if (user._id === deleteID) {
                    return { ...user, status: "removal pending" };
                  }
                  return user;
                });
                return updateduser;
              });
            }
          })
          .catch((err) => console.log(err?.message));
      else {
        if (deleteID && fileLength) {
          errorNotification("Please upload at least one new document");
        } else {
          errorNotification("Please upload necessary documents");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      errorNotification,
      fileStatus,
      setFileStatus,
      successNotification,
      updateDataRoomFileStatus,
    ]
  );

  const getRemovalFiles = useCallback(async () => {
    if (location?.pathname.includes("/co-owners-email-remove/")) {
      const urlId = location.pathname.split("/");
      const ownerId = urlId[urlId.length - 1];
      setCoownerID(ownerId);
      // setLocalStorage("ownerId", { ownerIdd: ownerId });

      await get(`${APIS.GETREMOVEALDOCUMENT}?coOwnerId=${ownerId}`).then(
        (res) => {
          console.log("deletedResponse", res);
          if (res?.data?.token) {
            const registrations = JSON.parse(
              localStorage.exchageAppRegistration || "[]"
            );
            const isDark: any = localStorage.getItem("darkMode") ?? "";
            removeLocalStorage();
            localStorage.setItem("darkMode", isDark || "true");
            isDark === "true" ? setThemeDark(true) : setThemeDark(false);
            deleteCookie("userDetails");
            // localStorage.setItem(
            //   "exchageAppRegistration",
            //   JSON.stringify(registrations)
            // );
            const loginDetails = {
              token: res?.data?.token,
              phone: "",
              isLoggedIn: true,
            };
            setOnRemovalFiles(res?.data);
            setCookieToken("userDetails", loginDetails);
            setUserInfo(loginDetails);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const denyRemovalRequest = useCallback(
    async (payload: any, id: string) => {
      setRemovalDecision(false);
      await denyRemoval(`${APIS.REMOVEACCEPTANCE}/${id}`, payload).then(
        (res) => {
          if (res?.success) {
            successNotification(
              res?.message || "You have successfully rejected removal request"
            );
            setRemovalDecision(true);
            navigate(COOWNERSREMOVALREQUESTCANCEL);
          } else {
            errorNotification("Something went wrong");
          }
        }
      );
    },
    [
      denyRemoval,
      successNotification,
      navigate,
      COOWNERSREMOVALREQUESTCANCEL,
      errorNotification,
    ]
  );
  const AcceptRemovalRequest = useCallback(async () => {
    setRemovalDecision(false);
    const payload = {
      isAccepted: true,
    };
    await approveRemoval(`${APIS.REMOVEACCEPTANCE}/${coownerId}`, payload).then(
      (res) => {
        if (res?.success) {
          successNotification(
            res?.message || "You have successfully accepted removal request"
          );
          setRemovalDecision(true);
          setTimeout(() => {
            navigate("/exchange");
          }, 4000);
        } else {
          errorNotification("Something went wrong");
        }
      }
    );
  }, [
    coownerId,
    errorNotification,
    navigate,
    approveRemoval,
    successNotification,
  ]);

  const getCoownersDocument = useCallback(
    async (id: string) => {
      await getCoownerDocument(`${APIS.GETREMOVEALDOCUMENT}/${id}`).then(
        (res) => {
          if (res) {
            setRemovalFiles(res?.data);
          }
        }
      );
    },
    [getCoownerDocument, setRemovalFiles]
  );

  const cancelCoownerRemove = useCallback(
    (id: any, setManageCoownerId?: any) => {
      cancelRomoval(`${APIS.COOWNERDENYREQUEST}/${id}`).then((res) => {
        if (res) {
          setInvitedUser((prev) => {
            const updateduser = prev.map((user) => {
              if (user._id === id) {
                return { ...user, status: "joined" };
              }
              return user;
            });
            return updateduser;
          });
          setManageCoownerId("");
        }
      });
    },
    [cancelRomoval, setInvitedUser]
  );

  const deleteUplaodedFile = useCallback(() => {
    if (deleteFileStatus?.length > 0) {
      const deleteFilePromise = deleteFileStatus?.map((id: any) =>
        deleteFile(`${APIS.UPLOADDOCUMENT}/${id}`)
      );
      Promise.all(deleteFilePromise).then((responses) => {
        const allSuccess = responses.every((res) => res?.success === true);
      });
    }
  }, [deleteFile, deleteFileStatus]);
  return {
    removeAuthoisedUser,
    uploadDocument,
    uploadedFileStatus,
    updateDataroomFileLoading,
    denyLoading,
    acceptLoading,
    removalDecision,
    getRemovalFiles,
    removalFileLoading,
    getCoownersDocument,
    cancelCoownerRemove,
    removalFilesLoading,
    denyRemovalRequest,
    AcceptRemovalRequest,
    deleteUplaodedFile,
  };
};
