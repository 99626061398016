import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./email-verify-notification.scss";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loadingState, LoginUserState, userPersonalDetails } from "../../states";
import { Button } from "../button";
import { useNetwork, useNotification, useCookie } from "../../hooks";
import { APIS, mobileApps } from "../../constant";
import { Loader } from "../Loader2";
import { ROUTES } from "../../routes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { showEmailNotificationState } from "states";
import { useMediaQuery } from "react-responsive";
import { db, trackEventSG } from "libs";
import { ref, onValue } from "firebase/database";
import { ENVIRONMENT , REACT_APP_HOST_URL} from "envs";
import { UAParser } from "ua-parser-js";
import { Compliance } from "@views/exchange/components/compliance";
import NewModal from "@components/new-modal/new-modal";

export const EmailVerifyNotification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const personalData = useRecoilValue(userPersonalDetails);
  const [showEmailNotification, setShowEmailNotification] = useRecoilState(
    showEmailNotificationState
  );
  const { successNotification, errorNotification } = useNotification();
  const { post: resendVerifyMail, loading } = useNetwork();
  const [searchParams] = useSearchParams();
  const { get: getCookieData, set: setCookieToken, deleteCookie } = useCookie();
  const setUserInfo = useSetRecoilState(LoginUserState);
  const [isShowEmailModal,setShowEmailModal] = useState(false)

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { ANDROID_APP, IOS_APP } = mobileApps;

  const { get } = useNetwork({ updateState: false });

  const parser = new UAParser(window.navigator.userAgent);
  const result = parser.getResult();
  const deviceOS = result?.os?.name;

  const handleMobileRedirect = () => {
    if(deviceOS === "Android"){
      window.location.href = ANDROID_APP;
    }
    else{
      window.location.href = IOS_APP;
    }
  };

  useEffect(() => {
    const dataRef = ref(
      db,
      `update-verify-email-${ENVIRONMENT}/${personalData?.id}`
    );

    const unsubscribe = onValue(dataRef, (snapshot: { val: () => any }) => {
      const data = snapshot.val();
      if(data){
        setShowEmailNotification(!data?.isVerifiedEmail);
      }
    });

    return () => unsubscribe();
  }, [personalData?.id]);

  useEffect(() => {
    const userData = getCookieData("userDetails");
    if (!userData) return;
    !!personalData.email
      ? setShowEmailNotification(!personalData.isVerifiedEmail)
      : setShowEmailNotification(true);
  }, [personalData, getCookieData, setShowEmailNotification]);

  const onClickResend = useCallback(async () => {
    const {id: userId} = personalData;
    if (loading) return;
    const res = await resendVerifyMail(APIS.ResendVerificationEmail, {});
    if (res?.success) {
      const eventData = { timestamp: new Date(), userId};
      trackEventSG("email_verification_started_web", eventData);
      setShowEmailNotification(true);
      return successNotification(res?.message);
    } else
      return errorNotification(
        res?.message || "Something went wrong. Please try again"
      );
  }, [loading]);

  useEffect(() => {
    const code = searchParams.get("verificationCode");
    if (code && location.pathname === ROUTES.EMAILVERIFY) {
      handleVerifyEmail();
    }
  }, []);

  const onCloseEmailUpdateModal=()=>{
    setShowEmailModal(false)
  }

  const onOpenEmailUpdateModal=()=>{
    setShowEmailModal(true)
  }

  const handleVerifyEmail = useCallback(async () => {
    const code = searchParams.get("verificationCode");
    const res = await get(`/verify-email/${code}`);

    if (res?.data?.success) {
      deleteCookie("userDetails");
      successNotification(res?.data?.message);
      const loginDetails = {
        token: res?.data?.token,
        phone: "",
        isLoggedIn: true,
      };
      // TODO: uncomment this code and remove below condition when we implement deep linking
      // if (isMobile) {
      //   setTimeout(() => {
      //     navigate(ROUTES.CONTINUEINMOBILE);
      //   }, 2000);
      // } else {
      //   setCookieToken("userDetails", loginDetails);
      //   setUserInfo(loginDetails);
      //   navigate(ROUTES.EXCHANGE);
      // }
      if (isMobile) {
        window.location.href=REACT_APP_HOST_URL + "/mpc-mobile-redirect";
      } else {
        setCookieToken("userDetails", loginDetails);
        setUserInfo(loginDetails);
        navigate(ROUTES.EXCHANGE);
      }
    } else if (res?.message === "Invalid token.") {
      navigate(ROUTES.EMAILVERIFY_DENIED);
      if (isMobile) {
        window.location.href=REACT_APP_HOST_URL + "/mpc-mobile-redirect";
      }
    } else {
      return errorNotification(
        res?.message ||
          "Something went wrong, when verifying email. Please try again"
      );
    }
  }, [
    deleteCookie,
    errorNotification,
    get,
    isMobile,
    navigate,
    searchParams,
    setCookieToken,
    setUserInfo,
    successNotification,
  ]);

  const renderEditUserDetailsModal = useMemo(() => {
    return (
      <NewModal
        isOpen={isShowEmailModal}
        closeModal={onCloseEmailUpdateModal}
        modalName={"Update email address"}
        title="Update email address"
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        className="Modal__wrapper exchange-modal-wrapper update-email-address-modal"
      >
        <Compliance
          isEditingUserDetails
          handleSuccess={onCloseEmailUpdateModal} handleClose={onCloseEmailUpdateModal}
          disabledData={{ firstName: true, lastName: true }}
        />
      </NewModal>
    );
  }, [isShowEmailModal]);

  return (
    <>
      {/* {personalData.id && !personalData.email && showEmailNotification ? (
        <div className="resend-verify-container resend-verify-hover" onClick={onOpenEmailUpdateModal}>
          Please click here to update your email.
          <i
            className="ri-close-line close-icon"
            onClick={(e) => {
              e.stopPropagation();
              setShowEmailNotification(false)
            }}
          />
        </div>
      ) : (
        !!personalData.email &&
        showEmailNotification && (
          <div className="resend-verify-container">
            Email is not verified. Please check{" "}
            <span>{personalData.email}</span> for a link to verify your email.
            <Button
              type="button__small resent-btn"
              label={loading ? <Loader dimension={12} /> : "Resend"}
              handleClick={onClickResend}
            />
            <i
              className="ri-close-line close-icon"
              onClick={() => setShowEmailNotification(false)}
            />
          </div>
        )
      )}
      {renderEditUserDetailsModal} */}
    </>
  );
};
