import useDarkMode from "use-dark-mode";
import cn from "classnames";

import styles from "./mobile-view.module.sass";
import { Image } from "@storybook";
import { mobileApps } from "constant";
import MpcMobileRedirect from "./SignIn/components/mpc-wallet/mpc-mobile-redirect";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { imageConfig } from "@utils/imageConfig";
import { MORNING_LINE } from "./exchange/constants";
import { LoaderLine } from "@components/LoaderLine";

const Mobileview = () => {
  const darkMode = useDarkMode(false);
  const { IOS_APP, ANDROID_APP } = mobileApps;
  const { config,loading } = usePermissionContext();
  const {svg: {appstore, android_appstore}, logo: {  Liquid_small, morningLineSmallLight, morningLineSmallDark }} = imageConfig;

  return (
    <>
      <div className={cn( styles.page )}>
        <div className={styles.main}>
          <div className="logoSection">{loading ? <LoaderLine /> : <Image fileName={ config?.name === MORNING_LINE ? (darkMode?.value ? morningLineSmallLight : morningLineSmallDark) : Liquid_small} width="64px" />}</div>
          
          <div className={styles.title}>
            {config?.name === "Morning Line"
              ? `Mobile App Coming Soon!`
              : `${config?.name} mobile app is available for both iOS and Android platform`}
          </div>

          <div className={styles.text}>
            {config?.name === "Morning Line"
              ? "Morning Line is currently available only on desktop. In the meantime, please access the platform from your desktop for the best experience."
              : ""}
            {/* Liquidity mobile app is available for both <br/> iOS and Android platform */}
          </div>
          {loading? "" : config?.name !== MORNING_LINE && <div
            className={styles.app_buttons}
          >
            <a href={IOS_APP} target="_blank" >
              <Image fileName={appstore} />
            </a>
             <a href={ANDROID_APP} target="_blank" >
            <Image fileName={android_appstore} />
          </a>
          </div>}
        </div>
        {/* <div className={styles.footer}>
        <Image fileName={"images/Originalwordmark.svg"} />
      </div> */}
      </div>
    </>
  );
};

export default Mobileview;
