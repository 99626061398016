import { useCallback, useEffect, useMemo, useState } from "react";
import { CountryCode, ICountries, Loader, LoaderLine } from "components";
import parsePhoneNumber, { CountryCode as LibCountryCode } from "libphonenumber-js";

import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { AuthTypeState } from "views/SignIn/stores";
import { LoginPhoneNumberMeta, LoginPhoneNumberState, userPersonalDetails } from "states";
import style from "./signin-form.module.sass";
import "../coming-soon/coming-soon.scss";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import { ROUTES } from "@routes/constants";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import {
  COUNTRY_PHONE_LENGTHS,
  SOCIAL_LOGIN_OPTIONS,
  SocialLoginOption,
} from "./constant";
import { trackEventGA, trackEventSG } from "libs";
import { imageConfig } from "@utils/imageConfig";
import ListYourFund from "../../../../assets/images/ListYourFund.png"

import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import useDarkMode from "use-dark-mode";
import classNames from "classnames";
import { SocialLoginOptions } from "../social-login-options";



import "./signin-form.scss";
import { useCookie } from "@hooks/cookie";
import { useLogin } from "@views/SignIn/hooks/login";


export const SignInForm = () => {
  const navigate = useNavigate();
  const setAuthType = useSetRecoilState(AuthTypeState);
  const [loginPhoneNumber, setLoginPhoneNumber] = useRecoilState(
    LoginPhoneNumberState
  );
  const userDetails = useRecoilValue(userPersonalDetails);

  const [isLoading, setIsLoading] = useState(false);
  const { errorNotification } = useNotification()
  const { post: verifyPhone } = useNetwork();
  const { post: sendOtp } = useNetwork();
  const { get } = useCookie()
  const { loginUser } = useLogin();
  const { TERMS_SERVICE, PRIVACY_POLICY, BIOMETRIC_POLICY, CONTACT_US, } = ROUTES;
  const [phoneNumberLengths, setPhoneNumberLengths] = useRecoilState(LoginPhoneNumberMeta);

  const [isChecked, setIsChecked] = useState(false);

  const [showPhoneError, setShowPhoneError] = useState(false);
  const [changePhoneError, setChangePhoneError] = useState(false);
  const [showAgreeError, setShowAgreeError] = useState(false);
  const { trackPageEvent, trackEvent } = useFullStoryTrackEvent();
  const [formatError, setFormatError] = useState<string | null>(null);
  const { handleLogout } = useNetwork();
  const { config, loading } = usePermissionContext();
  const darkMode = useDarkMode(false)

  const { OTPVERIFY, CHOOSEMETHOD } = ROUTES

  const {images: {LoginBg, LoginImageCard,MorningLineBanner}, logo: {LiquidityLogo}} = imageConfig;

  useEffect(() => {
    trackPageEvent({
      pageName: 'Login',
    });
    handleLogout(false)
  }, []);

  useEffect(()=>{
    if(!darkMode.value){
      darkMode.toggle()
    }
  }, [darkMode])

  const countryCode = loginPhoneNumber?.countryLabel as LibCountryCode || "US";

  const phoneNumberValidation = parsePhoneNumber(
    loginPhoneNumber?.phone,
    countryCode,
  );

  const isDisabled = useMemo(() => {
    const { phone } = loginPhoneNumber;
    const { minLength, maxLength } = phoneNumberLengths;

    const isPhoneValidLength =
      phone.length >= minLength && phone.length <= maxLength;

    return !(
      isPhoneValidLength &&
      isChecked &&
      phoneNumberValidation?.isValid() &&
      phoneNumberValidation?.isPossible()
    );
  }, [loginPhoneNumber, phoneNumberLengths, isChecked, phoneNumberValidation]);


  const handleOnClick = useCallback(() => {
    const eventData = {
      mobile_number: loginPhoneNumber?.phone,
      timestamp: new Date(),
    };

    trackEventGA("enter_mobile_number_web", eventData,userDetails?.email);
    trackEventSG("enter_mobile_number_web", eventData, userDetails?.email);
    handleBlur();

    if(!isDisabled){
      const { phone, countryCode } = loginPhoneNumber;
      const { minLength } = phoneNumberLengths;
      if (phone.length < minLength) {
          errorNotification(`Phone number must be ${phone.length} character long`);
        return;
      }

      const loginData = {
        phone,
        countryCode,
      };

      setIsLoading(true);
      verifyPhone(APIS.VerifyPhone, loginData).then((res: any) => {
        if (!res?.isRegisteredUser) {
          localStorage.setItem("isNewUser", "true");
          trackEvent(`Signup`, {
            ...loginData,
          });
          sendOtp(APIS.PhoneCodes, loginData);
          navigate(OTPVERIFY);
          setAuthType("signup");
        } else {
          trackEvent(`Login`, {
            ...loginData,
          });
          setAuthType("login");
          navigate(CHOOSEMETHOD);
        }
      });
    }
   
  }, [isDisabled, loginPhoneNumber ,  userDetails?.email]);

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
  }



  const handleSocialSignUp = useCallback(async (el: SocialLoginOption) => {
    (window as any).location.href = el.url
  }, []);

  const onHandleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | any) => {
      const { value } = e.target;
      let digitsOnly = value.replace(/\D/g, ""); // Remove non-digit characters
      let codeDigits = `${loginPhoneNumber?.countryCode}`?.replace(/\D/g, ""); // Remove non-digit characters
      const { minLength, maxLength } = phoneNumberLengths;
      if (digitsOnly.startsWith(codeDigits) && digitsOnly.length > minLength) digitsOnly = digitsOnly.substring(codeDigits?.length || 0);
      if (digitsOnly.length > maxLength) return;

      if (loginPhoneNumber?.countryCode === "+91" && digitsOnly.length > maxLength) {
        setShowPhoneError(true);
        setChangePhoneError(true);
        setLoginPhoneNumber((prev) => ({ ...prev, phone: "" }));
        return;
      } else if (digitsOnly.length >= minLength || digitsOnly.length === maxLength || digitsOnly.length === 0) {
        setShowPhoneError(false);
        setChangePhoneError(false);
      }

      setLoginPhoneNumber((prevState) => ({
        ...prevState,
        phone: digitsOnly,
      }));

      if (e.keyCode === 13 && digitsOnly.length === minLength && isChecked) {
        handleOnClick();
      }
    },
    [phoneNumberLengths, loginPhoneNumber?.countryCode, setLoginPhoneNumber, isChecked, handleOnClick]
  );

  const onCheckBoxClick = useCallback(
    () => {
    setShowAgreeError(false);
    !isLoading && setIsChecked((prev) => !prev);
    },
  [isLoading]);

  const handleCountryCode = useCallback(
    (e: ICountries) => {
      const countryCode = e.label;
      const countryLabel = e.code;
      const countryInfo = COUNTRY_PHONE_LENGTHS[countryCode];

      const defaultMinLength = 9;
      const defaultMaxLength = 11;

      const minLength = countryInfo ? countryInfo?.minLength : defaultMinLength;
      const maxLength = countryInfo ? countryInfo?.maxLength : defaultMaxLength;

      let updatedPhone = loginPhoneNumber.phone;


      setLoginPhoneNumber((prevState) => ({
        ...prevState,
        countryCode,
        phone: updatedPhone,
        countryLabel
      }));

      setPhoneNumberLengths({ minLength, maxLength });
      setShowPhoneError(false);
      setChangePhoneError(false);
    },
    [loginPhoneNumber.phone, setLoginPhoneNumber, setPhoneNumberLengths]
  );

  const handleBlur = () => {
    const { minLength, maxLength } = phoneNumberLengths;
    const phoneLength = loginPhoneNumber.phone.length;
    setShowAgreeError(false);
    if(loginPhoneNumber.phone.length === 0){
      setShowPhoneError(true);
      setChangePhoneError(true);
      setFormatError("Phone number can’t be empty. ");
    }
    else if (
      loginPhoneNumber.phone.length > 0 &&
      (phoneLength < minLength || phoneLength > maxLength)
    ) {
      setShowPhoneError(true);
      setChangePhoneError(true);
      setFormatError(null);
    } else if (
      (phoneLength === minLength || phoneLength === maxLength) &&
      !phoneNumberValidation?.isValid()
    ) {
      setShowPhoneError(true);
      setChangePhoneError(false);
      setFormatError("Please enter a valid format");
    } 
    else if (!isChecked) {
      setShowAgreeError(true);
    } 
    else {
      setShowPhoneError(false);
      setChangePhoneError(false);
      setFormatError(null);
    }
  };

  useEffect(() => {
    if (loginPhoneNumber?.countryCode === "+91") {
      setPhoneNumberLengths({ minLength: 10, maxLength: 10 });
    } else {
      setPhoneNumberLengths({ minLength: 10, maxLength: 11 });
    }
  }, [loginPhoneNumber?.countryCode]);


  const handleTermsService = useCallback(() => {
    window.open(TERMS_SERVICE, '_blank', 'rel=noopener noreferrer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePrivacyPolicy = useCallback(() => {
    window.open(PRIVACY_POLICY, '_blank', 'rel=noopener noreferrer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBiometricPolicy = useCallback(() => {
    window.open(BIOMETRIC_POLICY, '_blank', 'rel=noopener noreferrer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContact = useCallback(() => {
    window.open(CONTACT_US, '_blank', 'rel=noopener noreferrer')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleHeaderLogo = useCallback(() => {
    window.location.href = '/';
  }, [])

  return (
    <div className={style.loginSection}>
      <div className={style.loginContainer}>
        <div className={style.header}>
          {loading ? <LoaderLine /> : <img onClick={handleHeaderLogo} className={`pointer ${style.brand_logo}`} src={config?.name !== "Liquidity" ? config?.branding?.logo?.dark : LiquidityLogo} alt="Logo" />}
          <div className="header_nav">
            <img className={style.brand_fund_list} src={ListYourFund} alt="ListYourFund"/>
          </div>
        </div>

        <div className={style.wrapper}>
        <div className={style.inner_wrapper}>
          <div className={style.title_header}>
            <div className={style.title}>Enter your phone number to get started</div>
            <div className={style.sub_title}>
              If you're not registered, you'll be prompted to create an account.
            </div>
          </div>
              <SocialLoginOptions
                options={SOCIAL_LOGIN_OPTIONS}
                handleSelect={handleSocialSignUp}
              />
              <div className="login-divider">
                <span>or</span>
              </div>
          {showAgreeError && (
            <>
              <div className={style.alert}>
                <i className={`ri-close-circle-fill ${style.signInCrossIcon}`}/>
                <div className={style.alert_content}>
                  <p className={style.agreement}>Agreement required</p>
                  <p className={style.agreement__text}>
                    Please agree to the Terms of Service, Privacy Policy, and Biometric Policy by checking the box to continue.
                  </p>
                </div>
              </div>
            </>
          )}
              <form
                className={style.form_wrapper}
                autoComplete="on"
                onSubmit={handleFormSubmit}
              >
                <div
                  className={`${style.input_wrapper} signin-input-wrapper ${
                    showPhoneError ? style.inputField_error : ""
                  }`}
                >
                  <CountryCode
                    handleChangeCountry={handleCountryCode}
                    countryCode={loginPhoneNumber.countryCode as string}
                    showPhoneError={showPhoneError}
                  />
                  <input
                    type="tel" // Use input type "tel" to handle phone numbers
                    // minLength={9}
                    // maxLength={phoneNumberLengths.maxLength}
                    data-testid="phoneNumber"
                    className={`${style.input_field} ${
                      showPhoneError ? style.inputField_error_background : ""
                    }`}
                    onChange={onHandleChange}
                    onKeyDown={onHandleChange}
                    value={loginPhoneNumber.phone}
                    placeholder="XXXXXXXXXX"
                    name="phone"
                    autoComplete="tel"
                    // onBlur={handleBlur}
                  />
                  {showPhoneError && (
                    <span
                      className={"input__error"}
                      style={{
                        position: "absolute",
                        bottom: "-64%",
                      }}
                    >
                      <span> <i className={`ri-error-warning-fill ${style.signin_inputError__icon}`}/></span>
                      {formatError
                        ? formatError
                        : changePhoneError
                        ? `Number must have ${phoneNumberLengths.minLength} characters`
                        : "Number is required"}
                    </span>
                  )}
                </div>
                <div className={classNames(style.sub_title_small,{[style.SignInInputError]:showPhoneError})}>
                  <label className="main login-checkbox-label">
                    <input type="checkbox" className={style.checkboxInput} checked={isChecked} readOnly />
                    <span
                      className={`checkbox-container ${showAgreeError && "checkbox-error"}`}
                      onClick={onCheckBoxClick}
                    ></span>
                  </label>
                  <span>
                  By proceeding you agree to our  <span className={classNames("hover", style.termsCondition)} onClick={handleTermsService}>
                  Terms of Service
                </span>,  <span
                    className={style.termsCondition + " hover"}
                    onClick={handlePrivacyPolicy}
                  >
                  Privacy Policy
                </span>
                   &nbsp; and <span
                    className={style.termsCondition + " hover"}
                    onClick={handleBiometricPolicy}
                  >
                  Biometric Policy
                </span>
                </span>
                </div>

                <button
                  onClick={handleOnClick}
                  disabled={
                    isLoading
                    // || isDisabled
                  }
                  className={
                    // isDisabled
                    //   ? style.login_btn_disabled
                    //   :
                    isLoading ? style.login_btn_loading : style.login_btn
                  }
                >
                  {isLoading ? (
                    <Loader dimension={20} className="loader-white" />
                  ) : (
                    "Continue"
                  )}
                </button>
              </form>
            </div>

        <p className={style.disclaimer}>
          DISCLAIMER: Securities on this platform are offered by ARQ Securities
          (“ARQ”), a FINRA member broker-dealer and operator the ARQ
          Securities/ATS. ARQ is a wholly owned subsidiary of Satschel Inc.
        liquidity.io is a wholly owned subsidiary of Satschel Inc. and provides
        services to this Platform. By using the Site, you are subject to ARQ
        Securities Terms of Service and to our Privacy Policy.
          <br></br>
          <br></br>
          Please read these carefully before using the Platform. Although our
        Platform may describe opportunities to invest in various securities, we
        do not make recommendations regarding the appropriateness of any
          particular investment opportunity for any particular investor. We are
          not investment advisers and we do not provide investment advice or
          monitor, endorse, analyze, or recommend any securities. You should be
          aware that the value of investments in private securities may be
          impacted by limited disclosure of financial and other company
        information, and of transaction reporting; they may be less-liquid than
        comparable public-market securities and that private securities holdings
        are often subject to additional trading restrictions which may impact
        your ability to sell. You should consult your business adviser,
        accounting adviser, and/or attorney with respect to price, value, risk
        or other aspects of your security or investment, prior to entering into
        a transaction. Digital assets using blockchain, are speculative, involve
        a high degree of risk, are generally illiquid, may have no value, have
        limited regulatory certainty, are subject to potential market
        manipulation risks and may expose investors to loss of principal.
        Investments in private placements, start-up investments in particular,
        are also speculative and involve a high degree of risk. Investors must
        be able to afford the loss of their entire investment. Blockchain is a
        new technology and unproven in financial markets. Securities ownership
        is officially maintained at a securities custodian and the transaction
        agent's records supersede the blockchain. There is no guarantee that
        tokenization will enable any secondary market liquidity in the future
        and your investment may remain illiquid.
          <br></br>
          <br></br>
          <span>
            Arq Securities is a member of{" "}
            <a
              href="https://brokercheck.finra.org/firm/summary/289499"
              target="_blank"
              rel="noopener noreferrer"
              className={style.finraLink}
            >
              FINRA
            </a>{" "}
            and{" "}
            <a
              href="https://www.sipc.org/"
              target="_blank"
              rel="noopener noreferrer"
              className={style.finraLink}
            >
              SIPC:
            </a>
          </span>
          <br></br>
          ARQ Securities Contact:
          <br></br>
          Eric Choi
          {/* <br></br>
        dsukoff@arqadvisors.com */}
          <br></br>
          Tel: +1 (406) 405-0873
          <br></br>
          Address: 3350 Sugarbowl Cir., Whitefish, MT
        </p>
          <footer className={style.footer}>
            <span className={style.footer_link} onClick={handleContact}>Contact Us</span>
          </footer>
        </div>
      </div>
      <div className={style.LoginImageRight}>
        {config?.name === "Liquidity" && <img className={style.LoginBg} src={LoginBg} alt="Background_image" />}
        {loading ? "" : <img
          src={config?.name === "Morning Line"
                ? MorningLineBanner
            : LoginImageCard}
            alt="blue square"
          className={classNames(style.LoginCards, { "bannerImage": config?.name !== "Liquidity" })}
        />}
      </div>

    </div>
  );
};
export default SignInForm
