import { IActiveTabData } from "../type";

import React, { FC, useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";

import { AddWatchlist } from "components";
import {
  ActiveAssetTab,
  ActiveCurrencyState,
  MusicState,
  StockState,
  WatchlistState,
} from "states";
import { Price } from "../Price";
import { Tooltip as ReactTooltip } from "react-tooltip";

import styles from "./../Currency/Currency.module.sass";
import { useNetwork } from "hooks";
import { APIS } from "constant";
import { useNavigate } from "react-router-dom";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { Image } from "@storybook";
import { ACCREDITATION_LABEL } from "@views/exchange/components/Main/constants";
import { assestType, assetTabs } from "@views/exchange/constants";

import { imageConfig } from "@utils/imageConfig";

interface IWatchlistItem {
  item: IActiveTabData;
  index: number;
  lastTradedPrice: number;
  amount:number;
}

interface IWatchLits {
  [key: string]: any;
}

export const WatchlistItem: FC<IWatchlistItem> = ({ item, index }) => {
  //globle states
  const watchlist = useRecoilValue(WatchlistState);
  const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const music = useRecoilValue(MusicState);
  const navigate = useNavigate();
  //local states
  const [isImageError, setIsImageError] = useState(false);
  const [isImageFromToError, setIsImageFromToError] = useState(false);
  const { get: getAssetData } = useNetwork();

  const {
    id,
    symbol,
    name,
    marketPrice,
    change,
    changesPercentage,
    rawSymbol,
    image,
    imageFrom,
    imageTo,
    type,
    artistName,
    issuerDetails,
    accreditationType,
    amount,
    lastTradedPrice,
    realStatAddress
  } = item ?? {};
  const address = realStatAddress
  const {issuerName} = issuerDetails || {};

  const handleRowClick = useCallback(async () => {
    if (["music", "privates"].includes(watchlist[id]?.type)) {
      let baseData: IWatchLits | undefined = {};
      if (watchlist[id]?.type === "music") {
        baseData = music.find((item: IActiveTabData) => {
          return watchlist[id]?.id === item?.id;
        });
      } else if (watchlist[id]?.type === "privates") {
        baseData = watchlist[id];
      }
      if (baseData) {
        navigate(`/exchange/${baseData?.id}?type=Watchlist`);
      }
    } else {
      navigate(`/exchange/${id}?type=Watchlist`);
    }
  }, [watchlist, index, music, setActiveCurrency, getAssetData]);

  const getLogo = useMemo(() => {
    if (type === "forex") {
      if (imageFrom && imageTo && !isImageFromToError) {
        return (
          <div className={styles.imagewrapper}>
            <div className={styles.imagefromcontiner}>
              <img
                className={styles.imagefrom}
                src={imageFrom ?? ""}
                alt=""
                onError={() => setIsImageFromToError(true)}
              />
            </div>
            <div className={styles.imagetocontiner}>
              <img
                className={styles.imageto}
                src={imageTo ?? ""}
                alt=""
                onError={() => setIsImageFromToError(true)}
              />
            </div>
          </div>
        );
      } else {
        return symbol?.charAt(0).toUpperCase() ?? "?";
      }
    } else if (type === "music") {
      return (
        <Image
          url={image ?? ""}
          initials={name}
          className={styles.assetImage}
        />
      );
    } else {
      return image && !isImageError ? (
        <div className={styles.assetImageContain}>
          <img
            className={styles.assetImage}
            src={image ?? ""}
            alt=""
            onError={() => setIsImageError(true)}
          />
        </div>
      ) : (
        symbol?.charAt(0).toUpperCase() ?? "?"
      );
    }
  }, [
    image,
    imageFrom,
    imageTo,
    isImageError,
    isImageFromToError,
    symbol,
    type,
  ]);

  const classes = useMemo(
    () =>
      cn(styles.row, {
        [styles.active]:
          id === activeCurrency?.id && symbol === activeCurrency?.symbol,
      }),
    [activeCurrency?.id, activeCurrency?.symbol, id, symbol]
  );

  const { svg } = imageConfig;

  const issuerNameString = type === assestType.crypto ? "" : issuerName;
  
  return (
    <div onClick={handleRowClick} className={classes} key={index}>
      <div className={`${styles.left}`}>
        <div className={styles.assetImageContain}>
          {getLogo}
          {!!ACCREDITATION_LABEL[accreditationType || ""] &&
            <img className={styles.accreditationLabel} src={svg[accreditationType ?? '']} alt="Accreditation" />}
        </div>
        <div className={styles.symbol} data-tooltip-id={id}>
          { type === "real_estate" ? name : type === "horse_trade" ? symbol : limitFirstPart(
            type === "music"
              ? name
              : type === "horse_trade" ? name : mergeStringWithSymbol(issuerNameString, symbol?.toUpperCase(), "."),
            type === "music" ? 25 : 12,
            "."
          ) ?? "NA"}
          <ReactTooltip
            id={id}
            place="bottom"
            content={
              type === "real_estate" ? name :limitFirstPart(
                type === "music"
                ? name : type === "horse_trade" ? symbol 
                : mergeStringWithSymbol(issuerNameString, symbol?.toUpperCase(), "."),
                type === "music" ? 25 : 12,
                "."
              ) ?? "NA"
            }
            className={"theme-tooltip"}
          />
        </div>
        <div className={styles.compony_name}>
          {type === "real_estate" ? ((address?.city || address?.state || address?.country) ? (
            `${address?.city ?? ""}, ${address?.state ?? ""}, ${address?.country ?? ""}`.replace(/(, )+$/, "")
          ) : "--") : type === 'music' ? artistName : type === "horse_trade" ? name : mergeStringWithSymbol(issuerNameString, name, " ")}
        </div>
      </div>
      <Price
        marketPrice={lastTradedPrice || amount}
        change={change}
        changesPercentage={changesPercentage}
        symbol={rawSymbol ?? symbol}
        type={type}
      />
      <AddWatchlist item={item} assetType={type} />
    </div>
  );
};
