import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookie, useNetwork, useNotification, usePrevious } from "hooks";
import { ROUTES } from "routes";
import { ConfirmIdentity } from "./confirm-identity";
import styles from "./compliance.module.sass";
import {
  complianceDataState,
  signUpInfoState,
  loadingState,
  linkedDeviceState,
  userPersonalDetails,
  isStartVerificationNewState,
  reinitiateKycState,
  isIframeState,
  userDetailsState,
  OpenKycReviewModalState,
  onboardingCompleteBannerState,
} from "states";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { URL_HOST } from "helpers";
import { signupFormMessage } from "./constants";
import { APIS } from "constant";
import { KycReview } from "./kyc-review";
import { KycStart } from "./kyc-start";
import { Agree } from "./agree";
import { redirect } from "react-router-dom";
import { DvpIframe } from "@views/account/components/DvpModals/dvpIframe";
import { Loader } from "@components/Loader2";
import { Json } from "../../../../types/common";
import classNames from "classnames";
import { trackEventSG } from "libs";
import { removeLocalStorage } from "@utils/common";

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  accountName?: string;
}

interface IDisabledFormData {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  accountName?: boolean;
}

interface IShowFormDataError {
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isEmailError: boolean;
  isAccountNameError?: boolean;
}

interface ICompliance {
  isEditingUserDetails?: boolean;
  handleSuccess?: () => void;
  handleClose?: () => void;
  disabledData?: IDisabledFormData;
  setVerificationDone?: (val: boolean) => void;
  handleCloseModal?: () => void;
  showUserEditModal?: string;
}

export const Compliance = ({
  isEditingUserDetails = false,
  handleSuccess,
  disabledData,
  handleClose,
  setVerificationDone,
  handleCloseModal,
  showUserEditModal
}: ICompliance) => {
  const [iframeLoading, setIframeLoading] = useState(true);
  const complianceData = useRecoilValue(complianceDataState);
  const devicelist = useRecoilValue(linkedDeviceState);
  const [reinitiateKyc, setReinitiateKyc] = useRecoilState(reinitiateKycState);
  const formData = useRecoilValue(signUpInfoState);
  const userDetails = useRecoilValue(userDetailsState);
  const [isStartVerification, setIsStartVerification] = useRecoilState(
    isStartVerificationNewState
  );
  const prevComplianceData = usePrevious(complianceData);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const setUserLoading = useSetRecoilState(loadingState);
  const [isEmailLoading, setEmailLoading] = useState(false);
  const [personalData, setPersonalData] = useRecoilState(userPersonalDetails);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const setIframeExists = useSetRecoilState(isIframeState);
  const  setOpenKycReviewModal= useSetRecoilState(OpenKycReviewModalState);
  const setAccountVerify = useSetRecoilState(
    onboardingCompleteBannerState
  );

    const [showFormDataError, setShowFormDataError] =
    useState<IShowFormDataError>({
      isFirstNameError: false,
      isLastNameError: false,
      isEmailError: false,
      isAccountNameError: false,
    });

  const { LOGIN } = ROUTES;
  const { deleteCookie } = useCookie();
  const { get: getCookieData } = useCookie();
  const { patch, data, loading} = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { patch: removeLinkedDevice } = useNetwork();
  const [isReinitiate, setReinitiate] = useState<boolean>(false);
  const [reinitiateData, setReinitiateData] = useState({});
  const { get: getReIntiate, data: reInitiateData } = useNetwork();
  const {
    kycStatus,
    isOnboardingComplete,
    status,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
    kybDocStatus,
  } = complianceData.onboardingData;

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    questionnaireStatus,
    status,
    signDocStatus,
    kybDocStatus,
  ].some((status) => status === "pending");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
    kybDocStatus,
  ].some((status) => status === "rejected");

  const isAnyStatusProcessing = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    signDocStatus,
    kybDocStatus,
  ].some((status) => status === "processing");

  const logoutAction = () => {
    const registrations = JSON.parse(
      localStorage.exchageAppRegistration || "[]"
    );
    removeLocalStorage();
    deleteCookie("userDetails");
    deleteCookie("userDetails");
    window.location.href = LOGIN;
  };

  useEffect(() => {
    if (parentRef.current) {
      const iframe = parentRef.current.querySelector("iframe");
      setIframeExists(!!iframe);
    } else {
      setIframeExists(false);
    }
  }, [complianceData]);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []);

  const handleMessageEvent = (event: Json) => {
    const receivedData = event.data;
    if (receivedData?.type === "onLoaded") {
      setIframeLoading(false);
    }
  
    if (receivedData?.type === "onComplete") {
      if(receivedData?.to !== "/home"){
        setAccountVerify(true);
        setOpenKycReviewModal(true);
      }
      !!handleCloseModal && handleCloseModal()
      
    }
  };


  const onLogout = useCallback(() => {
    //remove all local storage expect but dont want to exchageAppRegistration
    //can not done one by one so keep in one varibale before delelating all localstorage
    //and create again localstorage with same key
    const userDetails = getCookieData("userDetails");

    const currentDeviceDetail = devicelist.find(
      (dev: any) => dev?.deviceToken === userDetails?.token
    );
    const payload = { ids: [currentDeviceDetail?._id], origin: "web" };
    removeLinkedDevice(
      `${APIS.UsersDeviceLogin}/${currentDeviceDetail?.userId}`,
      payload,
      { apiResponse: true }
    ).then((resp) => {
      logoutAction();
    }).catch((err) => {
      logoutAction();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicelist]);

  const {
    firstNameMessage,
    profileNameMessage,
    lastNameMessage,
    emailMessage,
    InvalidEmailMessage,
  } = useMemo(() => signupFormMessage, []);

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z -]+$/i;
    return !(!name || regex.test(name) === false);
  };

  const validateEmail = (email: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };
  

  useEffect(() => {
      if (data) {
        setUserLoading(false);
        !!handleSuccess && handleSuccess();
        successNotification(data?.message ?? 'Profile Updated');
      } else {
        setUserLoading(false);
        if (data?.message) {
          errorNotification(data?.message ?? "Something went wrong");
        } else {
          setIsConfirmed((prev) => !prev);
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,handleSuccess]);

  const handleProceed = useCallback(async () => {
   const {id: userId} =  userDetails?.data;
    const eventData = { 
      timestamp: new Date(), 
      user_id: userId 
  };
  trackEventSG("bank_account_addition_initiated_web", eventData);

    setEmailLoading(true);
    const { firstName = "", lastName = "", email = "", accountName = "" } = formData;
    // Initialize an array to store error messages
    const errorMessages = [];

    // if (firstName) {
    //   console.log("firstName", firstName)
    //   if (!validateName(firstName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isFirstNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isFirstNameError: true,
    //   }));
    //   errorMessages.push(firstNameMessage);
    // }

    // if (lastName) {
    //   if (!validateName(lastName.trim())) {
    //     errorMessages.push("Please enter alphabets only");
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: true,
    //     }));
    //   } else {
    //     setShowFormDataError((prev) => ({
    //       ...prev,
    //       isLastNameError: false,
    //     }));
    //   }
    // } else {
    //   setShowFormDataError((prev) => ({
    //     ...prev,
    //     isLastNameError: true,
    //   }));
    //   errorMessages.push(lastNameMessage);
    // }

    if(!disabledData?.accountName){
      if (accountName) {
        if (!validateName(accountName.trim())) {
          errorMessages.push("Please enter alphabets only");
          setShowFormDataError((prev) => ({
            ...prev,
            isAccountNameError: true,
          }));
        } else {
          setShowFormDataError((prev) => ({
            ...prev,
            isAccountNameError: false,
          }));
        }
      } else {
        setShowFormDataError((prev) => ({
          ...prev,
          isAccountNameError: true,
        }));
        errorMessages.push(profileNameMessage);
      }
    }

    if (email) {
      if (!validateEmail(email)) {
        errorMessages.push(InvalidEmailMessage);
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: true,
        }));
      } else {
        setShowFormDataError((prev) => ({
          ...prev,
          isEmailError: false,
        }));
      }
    }
    if (errorMessages.length > 0) {
      setEmailLoading(false);
      // errorNotification(errorMessages.join("\n"));
    } else {
      let payload: IFormData = {
        firstName: firstName,
        lastName: lastName,
      } as IFormData;

      if (!disabledData?.email) {
        payload.email = email?.toLocaleLowerCase()
      }

      if (!disabledData?.accountName) {
        payload.accountName = accountName
      }
      await patch(`${APIS.Users}/${complianceData.id}`, payload);
      setUserLoading(true);
      setPersonalData((prev) => ({
        ...prev,
        email: email?.toLocaleLowerCase(),
        accountName: accountName || ""
      }));
      setEmailLoading(false);
    }
    // setIsStartVerification(true)
  }, [InvalidEmailMessage, complianceData.id, disabledData?.accountName, disabledData?.email, formData, patch, profileNameMessage, setPersonalData, setUserLoading, userDetails?.data]);

  const { onboardingData, phone, countryCode } = complianceData;

  useEffect(() => {
    if (reinitiateKyc && userDetails?.data?.sessionId) {
      getReIntiate("/reinitiate-session").then((res: any) => {});
    }
  }, [getReIntiate, reinitiateKyc]);

  const isVerfificationComplete = useMemo(() => {
    // const isOnboardingCompleted = onboardingData?.status === "completed";
    // const hasNoRejections = !isAnyRejected;

    if(!reinitiateKycState){
      return true;
    }
    return false;

    // TODO: This Code is currently not in use
    // if (
    //   (status === "completed" && hasNoRejections && !reinitiateKyc && !isAnyStatusPending)
    //   ||
    //   (isOnboardingComplete && hasNoRejections && !isAnyStatusPending)
    //   ||
    //   (prevUserData?.onboardingData?.status === "pending" && isOnboardingCompleted && hasNoRejections && !isAnyStatusPending)
    // ) {
    //   return true;
    // }
    // return false;

  }, []);
  
  useEffect(() => {
    if (isVerfificationComplete && setVerificationDone) {
      setVerificationDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerfificationComplete]);

  useEffect(() => {
    if (reInitiateData) {
      setReinitiateData(reInitiateData?.data?.url);
    }
  }, [reInitiateData]);

const isDisabledHandleProceesButton = useMemo(() => {
  if(showUserEditModal === 'accountName'){
    return !formData?.accountName
  }
  if(showUserEditModal ==='email'){
    return !formData?.email
  }
  if (isEmailLoading) return true;
  // You can add more conditions here if needed
  return false;
}, [formData?.accountName, formData?.email, isEmailLoading, showUserEditModal]);
 
  const renderComponent = useMemo(() => {
    let url = `${
      onboardingData?.complexUrl
    }&allow=true&phone=${phone}&countryCode=${countryCode}${
      personalData?.email && `&email=${personalData?.email}`
    }&redirect=false`;
    if (reInitiateData) {
      url = `${
        reInitiateData.data.url
      }&allow=true&phone=${phone}&countryCode=${countryCode}${
        personalData?.email && `&email=${personalData?.email}`
      }&redirect=false`;
    }

    const isOnboardingCompleted = onboardingData?.status === "completed";

    const hasOnboardingRecentlyCompleted =
      !prevComplianceData.onboardingData?.isOnboardingComplete &&
      complianceData.onboardingData?.isOnboardingComplete;


    if (
      !isStartVerification &&
      (status === "pending" ||
        (isOnboardingCompleted && (isAnyRejected || isAnyStatusPending)) || hasOnboardingRecentlyCompleted || isAnyStatusProcessing) &&
      !isEditingUserDetails
    ) {
      return (
        // Remove this code for old start verification modal
        // <div className={styles.main_container}>
        //   <KycStart />
        //   <button
        //     className={`${styles.proceed_btn} ${styles.kyc_btnStart}`}
        //     onClick={() => {
        //       setIframeLoading(true);
        //       setIframeExists(true);
        //       setIsStartVerification(true);
        //     }}
        //   >
        //     Start verification
        //   </button>
        // </div>
        <div className={styles.main_container}>
          <KycReview
            onLogout={onLogout}
            setReinitiateKyc={setReinitiateKyc}
          />
        </div>
      );
    }

    if (!isEditingUserDetails) {
      if (isVerfificationComplete) {
        return (
          <div className={styles.main_container}>
            <KycReview
              onLogout={onLogout}
              setReinitiateKyc={setReinitiateKyc}
            />
          </div>
        );
      }

      return (
        <div className={!iframeLoading ? styles.main_container : ""} ref={parentRef}>
          <iframe
            src={url}
            title="Compliance"
            className={styles.compliance_iframe}
            allow="camera; microphone; geolocation"
            key={`${iframeLoading}`}
            style={{ display: iframeLoading ? "none" : "" }}
          />
          {iframeLoading && (
            <div className={styles.compliance_iframe_loader}>
              <Loader className="loader-blue" />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.main_container}>
        <ConfirmIdentity
          disabledData={disabledData}
          proceed={handleProceed}
          loading={loading}
          showFormDataError={showFormDataError}
          setShowFormDataError={setShowFormDataError}
        />
        <div className="compliance-btn-groups">
          <button
            className={`btn-cancel ${styles.proceed_btn} ${styles.kyc_btn}`}
            onClick={() => !!handleClose && handleClose()}
          >
            Cancel
          </button>
          <button
            className={classNames("btn-save-verify", styles.proceed_btn, styles.kyc_btn, { [styles.disbaled_btn]: isDisabledHandleProceesButton})}
            onClick={handleProceed}
            disabled={isDisabledHandleProceesButton}
          >
            {isEmailLoading ? <Loader dimension={20} /> : "Save and verify"}
          </button>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onboardingData?.complexUrl,
    onboardingData?.status,
    phone,
    countryCode,
    personalData?.email,
    reInitiateData,
    isStartVerification,
    status,
    isAnyRejected,
    isAnyStatusPending,
    isEditingUserDetails,
    disabledData,
    handleProceed,
    loading,
    showFormDataError,
    userDetails?.data?.sessionId,
    setIframeExists,
    setIsStartVerification,
    isVerfificationComplete,
    onLogout,
    setReinitiateKyc,
    handleClose,
    iframeLoading,
    isAnyStatusProcessing
  ]);

  return <>{renderComponent}</>;
};
