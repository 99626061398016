import React, { useEffect, useMemo } from "react";
import { Slide, ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import { initDB, INIT_CONFIGURATION, useCookie } from "hooks";
import { AllRoutes } from "./routes/routes";
import Mobileview from "views/mobile-view";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import { EmailVerifyNotification, KycSignupModal, ReactModal } from "./components";
import { useLocation } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import MobileEmailVerification from "@views/mobile-email-verify";
import MobileCoownerJoining from "@views/mobile-co-owner-joining";
import { useFirebaseNotification } from "@hooks/useFirebaseNotification";
import { logEvent } from "firebase/analytics";

import "./styles/app.sass";
import "variables/_variables.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isFormVisibleState, onboardingCompleteBannerState, OpenKycReviewModalState, reOpenKycSignupModalState, showUserEditModalState, TennetTokenState, userPersonalDetails, userTypeState } from "./states";
import ContinueInMobile from "@views/continueInMobile";
import { useFullStoryTrackEvent } from "./hooks/useFullStoryTrack";
import MpcWallet from "@views/mpc-wallet/mpc-wallet";
import MpcMobileRedirect from "@views/SignIn/components/mpc-wallet/mpc-mobile-redirect";
import AccountApprovalBanner from "@views/exchange/components/AccountApprovalBanner/accountApprovalBanner";
import { loadGTM, trackEventSG } from "libs";
import styles from "./App.module.sass"

import { ENVIRONMENT, REACT_APP_API_HOST } from "envs";
import { CLIENT_ENVIRONMENT } from "@routes/constants";

import Hubspot from "@components/Header/component/HubSpot/hubspot";
import { useVerificationsHook } from "@hooks/useVerificationsHook";
import classNames from "classnames";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "@views/error-page/error-page";
import { VerificationSteps } from "@views/verificationStep";
import { EditUserDetailsModal } from "@views/account/components/AccountBalances/editProfile";

initDB(INIT_CONFIGURATION);

const App = (props : any) => {
  const { pathname } = useLocation();
  const location = useLocation();
  
  const { onMessageListener } = useFirebaseNotification();
  const userDetails = useRecoilValue(userPersonalDetails);
  const reOpenKycSignupModal = useRecoilValue(reOpenKycSignupModalState);
  const  [OpenKycReviewModal,setOpenKycReviewModal]= useRecoilState(OpenKycReviewModalState);
   const accountVerify = useRecoilValue(onboardingCompleteBannerState);
  const { isOnboardingComplete = true, } =
  userDetails?.onboardingData || {};
  const { setUserIdentifire } = useFullStoryTrackEvent();
  const { get: getCookieData } = useCookie();
  const userDetail = getCookieData("userDetails");
  const { token: loginToken } = userDetail ?? {};
  // const isSmallScreen = useMediaQuery({ query: "(max-width: 767px) and (min-width: 375px)" });
  const isFormVisible = useRecoilValue(isFormVisibleState)
  const { handleVerifySwitchAccountEmail } = useVerificationsHook();
  const setTennetToken = useSetRecoilState(TennetTokenState)
  const  showUserEditModal = useRecoilValue(showUserEditModalState);
  useEffect(() => {
    const clientId = props?.props?.clientid;
    setTennetToken(clientId)
  }, [props?.props?.clientid])

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 767px) and (min-width: 375px)",
  });

  const allowedDesktopView = useMemo(() => {
    if (
      /mobile-verification|privacy-policy|terms-of-service|biometric-policy/.test(
        pathname
      )
    ) {
      return true;
    } else if (
      isSmallScreen &&
      /co-owners-email-remove|co-owner-removal-request|co-owner-request-cancel/.test(
        pathname
      )
    ) {
      return true;
    }
    return false;
  }, [pathname, isSmallScreen]);

  useEffect(() => {
    handleVerifySwitchAccountEmail();
  }, []);

  useEffect(() => {
    if (loginToken) {onMessageListener();}
  }, [loginToken, onMessageListener]);

  useEffect(() => {
    const clientId = props?.props?.clientid;
 
    if (clientId) {
      sessionStorage.setItem("tenantToken", JSON.stringify(clientId));
     }
  }, [])


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const utmSource = urlParams.get("utm_source");

    const eventName = loginToken ? "Dashboard_page_view_web" : "Login_page_view_web"

    trackEventSG(
      eventName,
      {
        page_url: location.pathname, utm_source: utmSource
      },
      userDetails?.email
    );
  }, [location]);


// Only for Exectives we will remove it later once all GA4 events setup.
  useEffect(() => {
    // Execute GTM script if:
    // 1. Environment is 'prod'
    // 2. No email is provided OR the email does not contain 'satschel.com'
    if (
      ENVIRONMENT === CLIENT_ENVIRONMENT.prod &&
      (!userDetails?.email || !userDetails?.email?.includes("satschel.com"))
    ) {
      loadGTM();
    }
  }, [userDetails?.email]);

  useEffect(() => {
    if (userDetails.id) {
      setUserIdentifire(userDetails.id, {
        displayName:
          !!userDetails.firstName && !!userDetails.lastName
            ? `${userDetails.firstName} ${userDetails.lastName}`
            : userDetails.phone,
        email: !!userDetails.email ? userDetails.email : userDetails.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.email, userDetails.firstName, userDetails.id, userDetails.lastName, userDetails.phone]);

  const hideKycSignUpModal = useMemo(() => {
    return /co-owners-email-remove|co-owner-removal-request|co-owner-request-cancel|login/.test(
      pathname
    );
  }, [pathname]);

  const MobileComponent = useMemo(() => {
    if (/email-verify/.test(pathname)) return MobileEmailVerification;
    else if (/mpc-wallet/.test(pathname)) return MpcWallet
    else if (/continue-in-mobile/.test(pathname)) return ContinueInMobile;
    else if (/co-owners-email-accept/.test(pathname))
      return MobileCoownerJoining;
    else if (/mpc-mobile-redirect/.test(pathname)) return MpcMobileRedirect;

    return Mobileview;
  }, [pathname]);

  const showEmailNotification = useMemo(() => {
    return !/mobile-verification/.test(pathname);
  }, [pathname]);

  const darkMode = useDarkMode(true);

  const rendertheme = useMemo(() => {  
    return darkMode.value ? "dark" : "light";
  }, [darkMode]);

  const isMobile = useMediaQuery({ query: "(max-width: 425px)" });

  return (
    //Managing All the Routes and notification container
    <ErrorBoundary fallback={<ErrorPage />}>
      <>
        {userDetails?.signDocStatus === "processing" ? (
          <AccountApprovalBanner />
        ) : (
          userDetails?.id && (
            <>
              {!userDetails?.onboardingData?.isOnboardingComplete ? (
                <VerificationSteps />
              ) : (
                <>{accountVerify && <AccountApprovalBanner approve={true} />}</>
              )}
            </>
          )
        )}

        {isMobile && !allowedDesktopView ? (
          <MobileComponent />
        ) : (
          <>
            {!hideKycSignUpModal && reOpenKycSignupModal && <KycSignupModal />}
            {OpenKycReviewModal && (
              <ReactModal
                visible={OpenKycReviewModal}
                onClose={() => setOpenKycReviewModal(false)}
                closeBtn={true}
                outerClassName={classNames(styles.KYCReviewModal)}
              >
                <KycReview handleClose={() => setOpenKycReviewModal(false)} />
              </ReactModal>
            )}
            {showUserEditModal && <EditUserDetailsModal />}
            {isFormVisible && <Hubspot />}
            <AllRoutes />
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              pauseOnHover
              transition={Slide}
              theme={rendertheme}
              limit={1}
            />
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default App;
