import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Image } from "@storybook";
import { useCookie } from "hooks/cookie";
import { ROUTES } from "@routes/constants";
import { useLocalStorage } from "@hooks/local-storage";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { accountLockedType } from "@states/user";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";
import { removeLocalStorage } from "@utils/common";

import "./accessDenied.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import classNames from "classnames";

export const AccessDenied = () => {
  const location = useLocation();
  const { access } = location.state || {}; // Safely accessing the state

  console.log({access});
  
  const { deleteCookie } = useCookie();
  const { LOGIN } = ROUTES;
  const { get: getLocalStorage } = useLocalStorage();
  const { put: switchUser } = useNetwork();
  const navigate = useNavigate();
  const userLockdType = useRecoilValue(accountLockedType);
  const darkMode = useDarkMode(false);

  const {svg: {lockedLight, lockedDark}, logo: {Liquid}} = imageConfig;
   const { config} = usePermissionContext()

  const handleLogout = useCallback(() => {
    const isDark: any = localStorage?.getItem("darkMode") ?? "";
    removeLocalStorage();
    if (isDark === "true") localStorage?.setItem("darkMode", "true");
    deleteCookie("userDetails");
    window.location.href = LOGIN;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCookie]);

  const handleBackAccount = useCallback(() => {
    const payload = { isPrimary: true };
    switchUser(`${APIS.SWITCHACCOUNT}/${userLockdType?.userId}`, payload).then(
      (res) => {
        if (res.success) {
          navigate("/exchange");
          window.location.reload();
        }
      }
    );
  }, [getLocalStorage, navigate, switchUser]);

  const primaryUser: any =
    getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";

  return (
    <div className="access-denied">
      {!access && (
        <div className="logo">
          <div className="logo1">
            <Image className={classNames("brand_logo", { "MlcLogo": config?.name !== "Liquidity" })} fileName={config?.name !== "Liquidity" ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.light : Liquid} />
          </div>
        </div>
      )}

      <div className="access--denied">
        <div>
          <img
            className="access-img"
            src={darkMode.value ? lockedDark : lockedLight}
            alt="logo"
          />
          {access ? (
            <h2>Access Denied</h2>
          ) : userLockdType.userAccountLocked ? (
            <h2>Your account is locked</h2>
          ) : (
            <h2>This account is locked</h2>
          )}

          {access ? (
            "You don't have permission to access this page"
          ) : (
            <p>
              Account temporarily locked for security, please contact our
              support team at{" "}
              <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${config?.supportEmail}`} target="_blank" rel="noopener noreferrer">{config?.supportEmail}</a>
            </p>
          )}

          {!access &&
            (userLockdType.userAccountLocked ? (
              <button onClick={handleLogout}>Back to login</button>
            ) : (
              <button onClick={handleBackAccount}>Back to my Account</button>
            ))}
        </div>
      </div>
    </div>
  );
};
