import { Json } from "../../types";

interface IOneTrustToken {
	url: string;
	tokenId: string;
}


export const Messages = {
  ZERO_PRICE_ERROR: "Price can't be 0!",
  ZERO_QTY_ERROR: "Quantity can't be 0!",
  ZERO_LIMIT_ERROR: "Limit can't be 0!",
  ERROR_MESSAGE: "Something went wrong.",
  NAME_ALREADY_TAKEN: "This name is already taken.",
  THIS_FIELD_REQUIRED: "This field is required",
  EXCHANGE_ADDED: "Exchange Added",
  EXCHANGE_EDITED: "Exchange Edited",
  FAILED_TO_ADD_EXCHANGE: "Failed to add exchange. try later !",
  FAILED_TO_EDIT_EXCHANGE: "Failed to edit exchange. try later !",
  ADDING_EXCHANGE: "Adding Exchange",
  EDITING_EXCHANGE: "Editing Exchange",
  MAXLENGTH_EXCEEDED: "Name can not be more than 35 characters",
  ORDER_PLACED:
    "Order placed successfully, please check the status in open orders",
  ORDER_MODIFIED: "Order modified, please check status in open orders section",
  ORDER_EXECUTED:
    "Order executed successfully, please check the status in executed orders",
  ORDER_MODIFY_EXECUTED:
    "Order modified and executed successfully, please check the status in executed orders",
  ORDER_PARTIALLY:
    "Order partially executed, please check status in  order section",
  ORDER_ERROR_MESSAGE:
    "We are unable to place the order right now, please try again later",
  LIMIT_BUY_MESSAGE: "limit can not be smaller than buy price !",
  LIMIT_SELL_MESSAGE: "limit can not be greater than sell price !",
  ORDER_MODIFIED_ERROR_MESSAGE:
    "We are unable to modify the order right now, please try again later",
  ORDER_CANCEL: "Order Canceled",
  ORDER_CANCEL_ERROR:
    "We are unable to cancel the order right now, please try again later",
  OP_CROSS_SELF_ERROR:
    "Buy and Sell for the same asset cannot be performed by the same user",
  OP_UNDERFUND_ERROR:
    "Insufficient funds to go ahead with the order, please add balance",
  BUYCONFIRMATION:
    "By submitting this Buy order, I am notifying my Cash Custodian (FirstBank) that I have submitted this order to ARQ Advisors ATS and I request from my Cash Custodian (FirstBank) that it should settle the transaction in accordance with the terms of this order when ARQ Advisors ATS notifies my Cash Custodian (FirstBank) of an execution of this Buy order.",
  SELLCONFIRMATION:
    "By submitting this Sell order, I am notifying my Securities Custodian (Liquidity Transfer Agent) that I have submitted this order to ARQ Advisors ATS and I request from my Securities Custodian (Liquidity Transfer Agent) that it should settle the transaction in accordance with the terms of this order when ARQ Advisors ATS notifies my Securities Custodian (Liquidity Transfer Agent) of an execution of this Sell order.",
};

export const BUY_SELL_ERROR = {
  OP_CROSS_SELF: "op_cross_self",
  OP_UNDERFUND: "op_underfunded",
};
export const DEFAULT_COUNTRY = {
  value: "United States",
  label: "+1",
  code: "US",
  image:
    "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
};

export const USER_DETAILS = "USERDETAILS";
export const USER_IMAGE = "USERIMAGE";
export const REGEX_ACCEPT_ONLY_NUMBER = /^[0-9]+$/;

export const FILE_EXTENSION_URL: Json = {
  pdf: "https://storage.googleapis.com/satschel-assets-public/assets/logo/pdf-logo-c001.svg",
  doc: "https://storage.googleapis.com/satschel-assets-public/assets/logo/doc-logo-c001.svg",
  docx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/docx-logo-c001.svg",
  csv: "https://storage.googleapis.com/satschel-assets-public/assets/logo/csv-logo-c001.svg",
  ppt: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DPPT.png",
  xls: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLS.png",
  xlsx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLSX.png",
  png: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1PNG.png",
  jpeg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPEG.png",
  jpg: "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Property_1JPG.png",
};

export const DOC_TYPES: Json = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
  doc: "application/msword",
};

export const VALID_IMAGE_FILE_FORMAT: any = {
  "image/jpeg": "jpeg",
  "image/jpg": "jpg",
  "image/png": "png",
  "image/svg": "svg",
};

export const ENVIRONMENTS: Json = {
  stage: "stage",
  preprod: "preprod",
  prod: "prod",
  beta: "beta",
};

export const defaultAccessHost = [
  "localhost",
  "exchange.stage.satschel.com",
  "exchange.pp.satschel.com",
  "exchange.beta.satschel.com",
  "liquidity.io",
];

export const productionLiquidityUrl = 'https://liquidity.io/';

export const PATRIOT_ACT_ONETRUST_TOKEN: IOneTrustToken = {
	url: 'https://privacyportal-cdn.onetrust.com/f28abd65-17b0-4df7-b558-d20b86121b10/privacy-notices/2b48fb50-f2fa-4f64-8ce9-ea4c30c2e621.json',
	tokenId: 'otnotice-2b48fb50-f2fa-4f64-8ce9-ea4c30c2e621',
};

export const AssetStatus = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const TRACKING_EVENTS = {
  PHONE_SIGNUP : "PSignup_completed"
};