import { PATRIOT_ACT_ONETRUST_TOKEN } from "constant";
import { useEffect } from "react";

export const PatriotAct = () => {
  useEffect(() => {
    const OneTrust = (window as any).OneTrust;
    OneTrust.NoticeApi.Initialized.then(function () {
      OneTrust.NoticeApi.Initialized.then(function () {
        OneTrust.NoticeApi.LoadNotices([PATRIOT_ACT_ONETRUST_TOKEN.url]);
      });
    });
  }, []);

  return (
    <div className="biometric-policy">
      <div
        id={PATRIOT_ACT_ONETRUST_TOKEN.tokenId}
        className="otnotice"
      ></div>
    </div>
  );
};
