import React, { useCallback, useEffect, useMemo, useState } from "react";

import { usePortfolioApis } from "hooks";

import "./my-portfolio.scss";
import { AllPortfolio, PortfolioAssets } from "./component";
import { MY_PORTFOLIO_TABS } from "./constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useRecoilValue } from "recoil";
import { firebaseIsFeatureEnableState, showEmailNotificationState } from "states";
import MyWallet from "@views/MyWallet/my-wallet";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const MyPortfolio = () => {
  const navigator = useNavigate();
  const initialQuerry = {};
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "All");
  const { fetchPortfolioList } = usePortfolioApis();
  const emailNotificationValue = useRecoilValue(showEmailNotificationState);
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);
  const { trackPageEvent } = useFullStoryTrackEvent();

  useEffect(() => {
    trackPageEvent({
      pageName: 'Portfolio',
    });
  }, []);

  useEffect(() => {
    const options = { appendNewData: false };
    fetchPortfolioList(initialQuerry, options);
  }, []);

  const handleChangeTab = useCallback((key: string) => {
    setActiveTab(key);
    navigator(`${ROUTES.WALLET}?tab=${key}`);
  }, []);

  const TABS: Record<string, string> = {
    'SBA7': "SBA(7a)"
  }

  useEffect(() => {
    const handlePopstate = () => {
      const params = new URLSearchParams(window.location.search);
      const tabParam = params.get('tab') || 'All';
      setActiveTab(tabParam);
    };

    handlePopstate();

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [setActiveTab]);

  const heightForEmailNotification =
    emailNotificationValue == true
      ? "calc( 100vh - 100px )"
      : "calc( 100vh - 56px )";

      const { SubRouteEnabled } = usePermissionContext();
 
      const getCommonSubFeatures = (
        assetsTab: string[],
        enabledSubFeatures: string[]
      ): string[] => {
        return assetsTab.filter(
          (subFeature) =>
            enabledSubFeatures
              .map((tab) => tab.toLowerCase())
              .includes(subFeature.toLowerCase()) // Compare in lowercase
        );
      };

      const assetPermissionTabs = useMemo(() => {
        const permissionArray = getCommonSubFeatures(
          MY_PORTFOLIO_TABS.map((tab) => tab.title),
          SubRouteEnabled("assets")
        );

        return MY_PORTFOLIO_TABS.filter(
          (tab) => (permissionArray?.includes(tab.title) && firebaseIsFeatureEnable[tab?.type] !== false) || tab.title === "All"
        );
      }, [SubRouteEnabled, firebaseIsFeatureEnable])
   
 
  return (
    <div
      className="portfolio-container" id="portfolio-assets-scroll"
      style={{ height: heightForEmailNotification }}>
      <h1 className="heading">My portfolio</h1>
      <div className="portfolio-tabs">
        {assetPermissionTabs?.map((tab) => (
          <div
            key={tab.type}
            className={`portfolio-tabs_item ${activeTab === tab.type ? "active" : ""
            }`}
            onClick={() => handleChangeTab(tab.type)}
          >
            {TABS[tab.title] ?? tab.title} 
          </div>
        ))}
      </div>

      {activeTab === "All" ? (
        <div className="portfolio-wallet-wrapper">
          <MyWallet />
          <AllPortfolio onClickAsset={(tabKey) => handleChangeTab(tabKey)} />
        </div>
      ) : (
        <PortfolioAssets activeAsset={activeTab} />
      )}
    </div>
  );
};

export default MyPortfolio;
